<template>
   <BaseForm 
        :form_class="''" 
        :cancelButton="'Zpět na předchozí stránku'"
        :acceptButton="'Odeslat žádost o přijetí'"
    >
    </BaseForm>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm'; 
import { useI18n } from 'vue-i18n';
// import { useStore } from 'vuex';
import useFormData from '@common/composable/BaseFormData';
import { useStore } from 'vuex';
import { computed, watch } from 'vue';

export default {
    name: "ApplicationFormPage1",
    components: {      
        BaseForm,
    },
    emits: ['goBack','closeForm','nextPage'],
    props: {
    },
    setup(props,context) {
        // const store = useStore();

        const i18n = useI18n();
        
        const { data,setRows,store_action,formActions,formInit } = useFormData();
        formInit();
        

        store_action.value = 'application/createItem';

        let rows = [
            {
                labels: ['Žádost se podává na zařízení'],
                items: [
                    {
                        title: "Zařízení",
                        type: 'label',
                        content: () => {
                            return data.value.school.fullname;
                        },
                        class: "w-stretch strong"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.child_fullname_birthday')],
                items: [
                    {
                        title: i18n.t('enrollment.child_fullname_birthday'),
                        type: 'label',
                        content: () => {
                            return data.value.jmeno_ditete+" "+data.value.prijmeni_ditete+' ('+data.value.datumNarozeni+')';
                        },
                        class: "w-stretch strong"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.nationality')],
                items: [
                    {
                        title: i18n.t('enrollment.nationality'),
                        type: 'text',
                        key: "statni_prislusnost",
                        class: "w-stretch",
                        required: true,
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.attendance')],
                items: [
                    {
                        title: i18n.t('enrollment.attendance'),
                        type: 'select',
                        key: "dochazka",
                        class: "w-stretch",
                        required: true,
                        options: () => [
                            {
                                title: 'celodenní',
                                code: 10,
                            },
                            {
                                title: 'polodenní',
                                code: 20,
                            }
                        ]
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.siblings')],
                items: [
                    {
                        title: i18n.t('enrollment.siblings'),
                        type: 'text',
                        key: "sourozenci",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.residence')],
                items: [
                    {
                        label: i18n.t('enrollment.street'),
                        title: i18n.t('enrollment.street'),
                        type: 'text',
                        key: "bydliste_ulice",
                        class: "w-l",
                        required: true,
                    },
                    {
                        label: i18n.t('enrollment.zip_code'),
                        title: i18n.t('enrollment.zip_code'),
                        type: 'text',
                        key: "bydliste_PSC",
                        required: true,
                        rules: {
                            lengthMin: 5,
                            lenghtMax: 10,
                        }
                    },
                    {
                        label: i18n.t('enrollment.city'),
                        title: i18n.t('enrollment.city'),
                        type: 'text',
                        key: "bydliste_mesto",
                        class: "w-stretch",
                        required: true,
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.representative')],
                items: [
                    {
                        label: i18n.t('enrollment.representative_name'),
                        title: i18n.t('enrollment.representative_name'),
                        type: 'text',
                        key: "zastupce_jmeno",
                        class: "w-l",
                        required: true,
                    },
                    {
                        label: i18n.t('enrollment.representative_residence'),
                        title: i18n.t('enrollment.representative_residence'),
                        type: 'text',
                        key: "zastupce_bydliste",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.contact')],
                items: [
                    {
                        label: i18n.t('enrollment.phone'),
                        title: i18n.t('enrollment.phone'),
                        type: 'text',
                        key: "zastupce_telefon",
                        class: "w-l",
                        errors: () => {
                            if((!data.value["zastupce_telefon"] || !data.value["zastupce_telefon"].trim()) && (!data.value["zastupce_email"] || !data.value["zastupce_email"].trim())){
                                return [i18n.t('enrollment.phone_email_required')];
                            }
                        }
                    },
                    {
                        label: i18n.t('enrollment.email'),
                        title: i18n.t('enrollment.email'),
                        type: 'text',
                        key: "zastupce_email",
                        class: "w-stretch",
                        errors: () => {
                            if((!data.value["zastupce_telefon"] || !data.value["zastupce_telefon"].trim()) && (!data.value["zastupce_email"] || !data.value["zastupce_email"].trim())){
                                return [ "" ];
                            }
                        }
                    },
                    {
                        type: 'label',
                        content: () => '',
                        icon: () => 'orange_star',
                        class: 'w-xxs'
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.note')],
                items: [
                    {
                        title: i18n.t('enrollment.note'),
                        type: 'textarea',
                        key: "poznamka",
                        class: "w-stretch"
                    }
                ]
            },
            {
                labels: [i18n.t('enrollment.submitted_by')],
                items: [
                    {
                        title: i18n.t('enrollment.submitted_by'),
                        type: 'text',
                        key: "submitted_by",
                        class: "w-stretch",
                        required: true,
                    }
                ]
            },
        ];
        
        setRows(rows);

                
        const goBack = () => {
            context.emit('goBack');
        };

        formActions.value.cancelForm = goBack;
        
        const store = useStore();

        const applied = computed(() => store.getters['application/applied']);

        watch(() => applied.value, () => {
            if(applied.value){
                context.emit('nextPage');
            }
        })

        formActions.value.afterSubmit = () => {
            // context.emit('closeForm');
        };


        return {
        }
    }
}
</script>

<style scoped>
.base-form{
    margin: 1rem;
}
.school-buttons{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 2rem;
}

.school-button>button{
    width: 12rem;
    height: 5rem;
    margin: 0.5rem;
}

</style>